<template>
    <div>
        <el-form :inline="true">
                <el-form-item label="操作员名称" label-width="100px">
                <el-input
                        v-model="searchForm.operUserName"
                        placeholder="操作员名称"
                        clearable>
                </el-input>
                </el-form-item>
            <el-form-item label="发生异常时间" label-width="100px">
                <el-date-picker
                        v-model="searchForm.createTime"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        placeholder="发生异常时间">
                </el-date-picker>
            </el-form-item>
<!--            <el-form-item label="日志来源" label-width="100px">-->
<!--                <el-select v-model="searchForm.isManage" clearable   placeholder="请选择日志来源">-->
<!--                    <el-option-->
<!--                            v-for="item in isManageList"-->
<!--                            :key="item.value"-->
<!--                            :label="item.label"-->
<!--                            :value="item.value">-->
<!--                    </el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->

            <el-form-item>
                <el-button @click="getlogList">搜索</el-button>
            </el-form-item>
        </el-form>

        <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                border
                stripe
                @selection-change="handleSelectionChange">

            <el-table-column
                    align="center"
                    fixed="left"
                    type="selection"
                    width="55">
            </el-table-column>

            <el-table-column
                    label="操作员名称"
                    align="center"
                    prop="operUserName"
                    width="200">
            </el-table-column>

            <el-table-column
                    prop="createTime"
                    align="center"
                    label="记录时间"
                    width="200">
            </el-table-column>
            <el-table-column
                    prop="operIp"
                    align="center"
                    width="200"
                    label="ip">
            </el-table-column>
            <el-table-column
                    align="center"
                    width="200"
                    prop="module"
                    label="模块">
            </el-table-column>
            <el-table-column
                    align="center"
                    width="200"
                    prop="operDesc"
                    label="操作描述">
            </el-table-column>
            <el-table-column
                    align="center"
                    width="200"
                    label="操作类型"
                    prop="operType"
            ></el-table-column>
            <el-table-column
                    prop="isAbnormal"
                    align="center"
                    width="200"
                    label="是否异常">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.isAbnormal === 0" type="success">正常</el-tag>
                    <el-tag size="small" v-else-if="scope.row.isAbnormal === 1" type="danger">异常</el-tag>
                </template>

            </el-table-column>
            <el-table-column
                    prop="isManage"
                    align="center"
                    width="200"
                    label="日志来源">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.isManage === 0" type="success">app</el-tag>
                    <el-tag size="small" v-else-if="scope.row.isManage === 1" type="danger">管理端</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    align="center"
                    width="200"
                    label="路径"
                    prop="operUri"
            ></el-table-column>
            <el-table-column
                    prop="icon"
                    align="center"
                    width="150px"
                    fixed="right"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="seeLogButton(scope.row.id)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 50, 100]"
                :current-page="current"
                :page-size="size"
                :total="total">
        </el-pagination>
        <SeeLog ref="seeLog" v-if="seeLogVisible" @refreshDataList="getlogList" ></SeeLog>
    </div>
</template>

<script>
    import SeeLog from "./SeeLog";
    export default {
        name: "Log",
        components:{
            SeeLog
        },
        data(){
            return{
                searchForm:{},
                tableData:[],
                total: 0,
                size: 10,
                current: 1,
                seeLogVisible:false
            }
        },
        created() {
            this.getlogList();
        },
        methods:{
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            handleSizeChange(val) {
                this.size = val
                this.getlogList()
            },
            handleCurrentChange(val) {
                this.current = val
                this.getlogList()
            },
            getlogList() {
                console.log(this.searchForm.createTime)
                this.searchForm.pageSize=this.size
                this.searchForm.pageNo=this.current
                this.$axios.post("/admin/logs/list",this.searchForm).then(res => {
                    this.tableData = res.data.records
                    this.size = res.data.size
                    this.current = res.data.current
                    this.total = res.data.total
                })
            },
            seeLogButton(id){
                this.seeLogVisible=true;
                this.$nextTick(()=>{
                    this.$refs.seeLog.init(id);
                })
            },
        }
    }

</script>

<style scoped>

</style>
